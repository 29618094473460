jQuery(function($) {

	/**
	 * Form validator
	 */
	(function () {

		var $forms = $('.js-validate'),
		    $submit = $forms.find('[type="submit"]'),
		    $input = $forms.find('[type="text"],[type="email"],[type="password"]'),
		    $inputLogin = $forms.find('[type="text"]'),
		    $inputPassw = $forms.find('[type="password"]');

		$forms.each(function (index) {
			var $jsForm = $(this),
				$jsSubmitDisabled = $jsForm.find('.js-submit-disabled'),
				$submit = $jsForm.find('[type="submit"]:not(.js-submit-disabled)');
			
			$jsForm.validate({
				errorPlacement: function errorPlacement(error, element) {}
			});

			$jsSubmitDisabled.on('click', function (e) {
				e.preventDefault();
				setTimeout(function () {

					if ($jsForm.valid()) {
						$jsForm.submit();
					}
				}, 0);
				return false;
			});

		});

		
		$input.on('keypress', function (e) {
			var $this = $(e.target);

			setTimeout(function () {
				if (
						$this.is('[type="password"]') && $this.parents('form:eq(0)').valid()
						|| 
						$this.is('[type="text"]') && (!$inputPassw || $inputPassw.val() != '') && $this.parents('form:eq(0)').valid()
					) {
					
					$submit
						.removeClass('btn--disabled')
						.removeAttr('btn--disabled');
				} else {
					$submit
						.addClass('btn--disabled')
						.addAttr('btn--disabled');
				}
			}, 0);
		});
		

	})();

	/**
	 * Filename for custom type="file" CSS
	 */
	(function () {
		/*
		var $inputFile = $('input[type=file][multiple]'),
		    filename = '';
		$inputFile.on('change', function () {
			var files = $(this)[0].files;

			for (var i = 0; i < files.length; i++) {
				filename += ' ' + $(this).val().split('\\').pop() + ';';
			}
			$("<span/>").html('&nbsp;' + filename + '&nbsp;').insertAfter($inputFile.parent());
		});
		*/
	})();

	/**
	 * Tooltip activate
	 */
	(function () {

		$('[data-toggle="tooltip"]').tooltip();
		
	})();


	/**
	 * Mmenu 
	 */
	(function () {

		$("#left-mmenu").mmenu({
			// options
			navbar: {
				title: "Меню"
			}
		});

		$("#right-mmenu").mmenu({
			"offCanvas": {
				"position": "right"
			},
			navbar: {
				title: "Меню"
			}
		});

	})();

});